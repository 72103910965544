import React from "react";
import { LIST_OF_ITEMS } from "./index.content";
import SVG_IC_GO from "../../../assets/images/vector/pages/main/ProValue/ic_go.svg";
import { getTrueClasses } from "../../../helpers";
import Container from "../../common/UI/Container";
import Countries from "./Countries";
import Description from "./Description";
import ScreenFull from "../../common/UI/ScreenFull";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const ProValue = () => (
  <section className="PgEM-PV">
    <Container mode="standard">
      <h2 className="PgEM-PV__title">
        Get everything you need to sell across 14 Amazon markets
      </h2>
      <Countries />
      <div className="PgEM-PV-list">
        {LIST_OF_ITEMS.map(
          ({ screen, buttons, title, isNumberedList, text }, i) => {
            const itemNumber = i + 1;
            return (
              <div
                className={`PgEM-PV-item PgEM-PV-item_item${itemNumber}`}
                key={title}
              >
                <Description
                  title={title}
                  text={text}
                  itemNumber={itemNumber}
                  isNumberedList={isNumberedList}
                />
                <div className={`PgEM-PV-S PgEM-PV-S_item${itemNumber}`}>
                  <ScreenFull screen={screen} />
                  {buttons.map((button, index) => (
                    <TrackedLink
                      key={button.text}
                      classes={getTrueClasses(
                        "PgEM-PV-B",
                        `PgEM-PV-B_b${index + 1}`
                      )}
                      category="main_page"
                      action={button.text}
                      path={button.path}
                      target={button.target}
                    >
                      {button.text}
                      <img className="PgEM-PV-B__icGo" src={SVG_IC_GO} alt="" />
                    </TrackedLink>
                  ))}
                </div>
              </div>
            );
          }
        )}
      </div>
    </Container>
  </section>
);

export default ProValue;
