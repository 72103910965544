import React, { useContext } from "react";
import { MediaAuthContext } from "../../../../store/context/media-auth-context";

const OPTIONS = {
  desk: {
    width: "14",
    height: "16",
    viewBox: "0 0 14 16",
    d: "M12.1417 6.28501C13.4364 7.06182 13.4364 8.93818 12.1417 9.71499L3.02899 15.1826C1.69594 15.9824 -2.23517e-07 15.0222 -2.23517e-07 13.4676V2.53238C-2.23517e-07 0.977792 1.69594 0.0175654 3.02899 0.817395L12.1417 6.28501Z",
  },
  mob: {
    width: "11",
    height: "14",
    viewBox: "0 0 11 14",
    d: "M9.67425 6.15634C10.2917 6.54929 10.2917 7.45071 9.67425 7.84366L1.53688 13.022C0.871153 13.4456 4.32134e-07 12.9674 4.32134e-07 12.1783V1.82167C4.32134e-07 1.03258 0.871151 0.554369 1.53688 0.978012L9.67425 6.15634Z",
  },
};

const Delta = () => {
  const {
    media: { isTabletOrLess },
  } = useContext(MediaAuthContext);
  const { width, height, viewBox, d } =
    OPTIONS[isTabletOrLess ? "mob" : "desk"];
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={d} fill="#FFFFFF" />
    </svg>
  );
};

export default Delta;

